import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-3 py-5" }
const _hoisted_2 = {
  class: "font-bold",
  for: "range"
}
const _hoisted_3 = { value: -1 }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuranVerses = _resolveComponent("QuranVerses")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.$t('intertext.quran_passages'),
    informationText: _ctx.infoText
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('intertext.selected')), 1),
        _withDirectives(_createElementVNode("select", {
          class: "w-48 ml-2 mr-4 bg-blue-light border",
          name: "range",
          id: "range",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRange) = $event))
        }, [
          _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.$t('intertext.select')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intertextDetail.payload.passages, (range, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: JSON.stringify(range),
              value: index
            }, _toDisplayString(_ctx.toText('Verse', 'Verses', range)), 9, _hoisted_4))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.selectedRange]
        ]),
        (_ctx.selectedRange >= 0)
          ? (_openBlock(), _createBlock(_component_QuranVerses, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "informationText"]))
}